@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Inter";
    line-height: 1.8rem;
  }
  h1 {
    @apply md:text-5xl text-3xl mb-8 mt-6;
  }
  h2 {
    @apply md:text-3xl text-2xl mb-4;
  }
}
body {
  margin: 0;
  @apply dark:bg-darkmode dark:text-lightmode bg-lightmode text-darkmode;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media (prefers-color-scheme: dark) {
  .active {
    font-weight: bold;
    color: #f5f5f5;
  }
}
@media (prefers-color-scheme: light) {
  .active {
    font-weight: bold;
    color: #111113;
  }
}
li {
  margin-top: 5px;
  margin-bottom: 5px;
}
a {
  cursor: pointer;
}
.footer {
  border-top: 1px solid #707070;
}
h1,
h2,
h3 {
  font-weight: bold;
}
li {
  list-style: disc;
  list-style-position: inside;
}
.markdown p,
ol {
  margin-bottom: 20px;
}

.markdown img {
  margin-bottom: 20px;
}
.markdown blockquote {
  padding-left: 1.5rem;

  @apply border-l-4 border-darksubtext text-lightsubtext dark:text-darksubtext;
}
.markdown a {
  color: #60a5fa;
  text-decoration: underline;
}

.markdown ul {
  margin-bottom: 20px;
  font-weight: 600;
  padding-left: 20px;
}
